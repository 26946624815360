<template>
  <div>
    <el-dialog
      :visible.sync="showDialog"
      :show-close="false"
      :close-on-click-modal="false"
      :fullscreen="webMode === 2"
      :custom-class="dialogClassName"
      @close="handleCloseDialog"
    >
      <div class="certificate-detail-info">
        <div class="certificate-detail-dialog-title">
          <img class="certificate-detail-dialog-close" src="/imgStatic/closeIcon.png" alt="" @click="handleCloseDialog" />
        </div>
        <div class="certificate-detail-dialog-content">
          <img :src="certificateDetail.img" alt="" class="certificate-detail-img">
          <div class="certificate-detail-title">{{ certificateDetail.name }}</div>
        </div>
      </div>
    </el-dialog>
    <div id="dialogScala" v-html="dialogScalaStyle"></div>
  </div>
</template>

<script>
export default {
    name: 'CertificateDetail',
    props: {
        showDialog: Boolean,
        certificateDetail: Object,
    },
    components: {
    },
    data() {
      return {
        dialogScalaStyle: '',
      };
    },
    created() {},
    methods: {
      handleCloseDialog() {
        this.$emit('colseDialog');
      },
    },
    computed: {
      webMode() {
        return this.$store.state.webMode;
      },
      dialogClassName() {
        return this.webMode === 1 ? 'certificate-detail-pc-dialog app-page-scala' : 'certificate-detail-mobile-dialog';
      },
      scala() {
        return this.$store.state.scala;
      },
    },
    watch: {
      scala: {
        immediate: true,
        handler(val, oldVal) {
          console.log(val, oldVal);
          if (this.val !== 1 && this.webMode === 1) {
            this.dialogScalaStyle = `<style>
              .certificate-detail-pc-dialog {
                width: ${50 / val}% !important;
                left: ${50 - 25 / val}% !important;
              }
            </style>`;
          }
        },
      },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  /deep/.certificate-detail-pc-dialog {
    position: fixed;
    left: 25%;
    top: calc(50% - 370px);
		margin: 0 !important;
    // height: 77%;
    height: 740px;
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 30px;
      padding-bottom: 10px;
      height: calc(100% - 40px);
      .certificate-detail-info {
        height: 100%;
        .certificate-detail-dialog-title {
          display: flex;
          align-items: center;
          .certificate-detail-dialog-close {
            margin-left: auto;
            width: 21px;
            cursor: pointer;
          }
        }
        .certificate-detail-dialog-content {
          margin: 0 -20px;
          padding-top: 10px;
          height: calc(100% - 31px);
          text-align: center;
          overflow-y: scroll;
          .certificate-detail-img {
            max-width: calc(100% - 6px);
            // max-height: calc(100% - 46px);
            border: 1px solid #b5b5b5;
          }
          .certificate-detail-title {
            margin: 20px 0;
            text-align: center;
            font-size: 16px;
            color: #333333;
            line-height: 16px;
          }
        }
        ::-webkit-scrollbar {
            width: 6px;
        }
        ::-webkit-scrollbar-thumb {
          background: grey;
          border-radius: 5px;
        }
        ::-webkit-scrollbar-track {
          background: #dcdcdc;
          border-radius: 5px;
        }
        ::-webkit-scrollbar-thumb:hover{
          background: #555;
        }
      }
    }
  }
  /deep/.certificate-detail-mobile-dialog {
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 30px;
      .certificate-detail-info {
        .certificate-detail-dialog-title {
          display: flex;
          align-items: center;
          .certificate-detail-dialog-close {
            margin-left: auto;
            width: 21px;
            cursor: pointer;
          }
        }
        .certificate-detail-dialog-content {
          padding-top: 40px;
          .certificate-detail-img {
            max-width: 100%;
            border: 1px solid #b5b5b5;
          }
          .certificate-detail-title {
            margin-top: 35px;
            text-align: center;
            font-size: 16px;
            color: #333333;
            line-height: 16px;
          }
        }
      }
    }
  }
</style>
