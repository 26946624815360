<template>
	<div class="home">
		<div class="content-box hidden-sm-and-down" v-if="webMode === 1">
            <img :src="adLink.pc" alt="" class="page-about-bg">
            <div class="intro-box page-about-product">
                <el-row class="page-content-box">
                    <el-col :span="11">
                        <div class="intro-box-left">
                            <div class="intro-box-title">{{ aboutProductAreaData.title }}</div>
                            <div class="intro-box-content html-data-box" v-html="aboutProductAreaData.description"></div>
							<div class="about-company-info">
								<div class="about-company-info-node" v-for="item in aboutTableData" :key="item.id">
									<div class="about-company-info-node-left html-data-box" v-html="item.title"></div>
									<div class="about-company-info-node-right" v-text="item.content"></div>
								</div>
							</div>
                        </div>
                    </el-col>
                    <el-col :span="13" class="intro-box-right-dom">
                        <div class="intro-box-right">
							<img :src="item.img" v-for="(item, index) in (aboutProductAreaData.pictures || [])" :key="index" alt="">
                        </div>
                    </el-col>
                </el-row>
			</div>
            <div class="intro-box marketing-network">
                <el-row class="page-content-box">
                    <el-col :span="11">
                        <div class="intro-box-left">
                            <div class="intro-box-title">{{ networkAreaData.title }}</div>
                            <div class="intro-box-content">
								<div class="marketing-network-title">{{ (networkAreaData.subTitle || [])[0] }}</div>
								<div class="html-data-box" v-html="networkAreaData.description"></div>
								<div class="marketing-network-title marketing-network-title-contract">{{ (networkAreaData.subTitle || [])[1] }}</div>
								<div class="html-data-box" v-html="networkAreaData.others"></div>
							</div>
                        </div>
                    </el-col>
                    <el-col :span="13" class="intro-box-right-dom">
                        <div class="intro-box-right">
							<img :src="item.img" v-for="(item, index) in (networkAreaData.pictures || [])" :key="index" alt="">
                        </div>
                    </el-col>
                </el-row>
			</div>
			<div class="intro-box quality-dom">
                <el-row class="page-content-box">
                    <el-col :span="11">
                        <div class="intro-box-left">
                            <div class="intro-box-title">{{ qualityAreaData.title }}</div>
                            <div class="intro-box-content html-data-box" v-html="qualityAreaData.description"></div>
                        </div>
                    </el-col>
                    <el-col :span="13" class="intro-box-right-dom">
                        <div class="intro-box-right">
                            <el-row :gutter="25" class="quality-intro-list">
								<el-col :span="12" v-for="(item, index) in (qualityAreaData.pictures || [])" :key="index">
									<div class="quality-intro-list-node">
										<img class="quality-intro-list-node-linear" src="/imgStatic/linear.png" alt="">
										<img :src="item.img" alt="">
										<div class="quality-intro-list-node-title" v-text="item.name"></div>
									</div>
								</el-col>
							</el-row>
                        </div>
                    </el-col>
                </el-row>
				<div class="page-content-box quality-intro-text-dom html-data-box" v-html="qualityAreaData.others"></div>
			</div>
            <div class="intro-box quality-certification">
				<div class="page-content-box">
					<div class="intro-box-title">{{ certificationAreaData.title }}</div>
					<div class="intro-box-content html-data-box" v-html="certificationAreaData.description"></div>
                    <div class="intro-box-list">
						<el-row :gutter="60">
							<el-col :span="8" v-for="item in certificateList" :key="item.name">
								<div class="intro-box-list-node" @click="handleShowCertificateDetail(item)">
									<div class="intro-box-list-node-img">
										<img :src="item.img" alt="">
									</div>
									<div class="intro-box-list-node-content">
										<div class="intro-box-list-node-content-title" v-text="item.name"></div>
									</div>
								</div>
							</el-col>
						</el-row>
					</div>
				</div>
			</div>
		</div>
		<div class="mobile-content-box hidden-md-and-up" v-if="webMode === 2">
			<img :src="adLink.h5" alt="" class="page-products-bg">
			<div class="intro-box page-about-product">
                <el-row class="page-content-box">
                    <el-col :span="24">
                        <div class="intro-box-left">
                            <div class="intro-box-title">{{ aboutProductAreaData.title }}</div>
                            <div class="intro-box-content html-data-box" v-html="aboutProductAreaData.description"></div>
							<div class="about-company-info">
								<div class="about-company-info-node" v-for="item in aboutTableData" :key="item.id">
									<div class="about-company-info-node-left html-data-box" v-html="item.title"></div>
									<div class="about-company-info-node-right" v-text="item.content"></div>
								</div>
							</div>
                        </div>
                    </el-col>
                    <el-col :span="24" class="intro-box-right-dom">
                        <div class="intro-box-right">
							<img :src="item.img" v-for="(item, index) in (aboutProductAreaData.pictures || [])" :key="index" alt="">
                        </div>
                    </el-col>
                </el-row>
			</div>
            <div class="intro-box marketing-network">
                <el-row class="page-content-box">
                    <el-col :span="24">
                        <div class="intro-box-left">
                            <div class="intro-box-title">{{ networkAreaData.title }}</div>
                            <div class="intro-box-content">
								<div class="marketing-network-title">{{ (networkAreaData.subTitle || [])[0] }}</div>
								<div class="html-data-box" v-html="networkAreaData.description"></div>
								<div class="marketing-network-title marketing-network-title-contract">{{ (networkAreaData.subTitle || [])[1] }}</div>
								<div class="html-data-box" v-html="networkAreaData.others"></div>
							</div>
                        </div>
                    </el-col>
                    <el-col :span="24" class="intro-box-right-dom">
                        <div class="intro-box-right">
                            <img :src="item.img" v-for="(item, index) in (networkAreaData.pictures || [])" :key="index" alt="">
                        </div>
                    </el-col>
                </el-row>
			</div>
			<div class="intro-box quality-dom">
                <el-row class="page-content-box">
                    <el-col :span="24">
                        <div class="intro-box-left">
                            <div class="intro-box-title">{{ qualityAreaData.title }}</div>
                            <div class="intro-box-content html-data-box" v-html="qualityAreaData.description"></div>
                        </div>
                    </el-col>
                    <el-col :span="24" class="intro-box-right-dom">
                        <div class="intro-box-right">
                            <el-row :gutter="15" class="quality-intro-list">
								<el-col :span="12" v-for="(item, index) in (qualityAreaData.pictures || [])" :key="index">
									<div class="quality-intro-list-node">
										<img class="quality-intro-list-node-linear" src="/imgStatic/linear.png" alt="">
										<img :src="item.img" alt="">
										<div class="quality-intro-list-node-title" v-text="item.name"></div>
									</div>
								</el-col>
							</el-row>
                        </div>
                    </el-col>
                </el-row>
				<div class="page-content-box quality-intro-text-dom html-data-box" v-html="qualityAreaData.others"></div>
			</div>
            <div class="intro-box quality-certification">
				<div class="page-content-box">
					<div class="intro-box-title">{{ certificationAreaData.title }}</div>
					<div class="intro-box-content html-data-box" v-html="certificationAreaData.description"></div>
                    <div class="intro-box-list">
						<el-row>
							<el-col :span="24" v-for="item in certificateList" :key="item.name">
								<div class="intro-box-list-node">
									<div class="intro-box-list-node-content" @click="handleShowCertificateDetail(item)">
										<div class="intro-box-list-node-content-title" v-text="item.name"></div>
                                        <img class="intro-box-list-node-content-arrow" src="/imgStatic/rightArrow.png" alt="">
									</div>
								</div>
							</el-col>
						</el-row>
					</div>
				</div>
			</div>
		</div>
		<CertificateDetail
			:showDialog="showMobileCertificateDetail"
			:certificateDetail="certificateDetailData"
			@colseDialog="handleCloseProductDialog"
		/>
	</div>
</template>

<script>
// @ is an alias to /src
import CertificateDetail from '@/components/CertificateDetail.vue';

import {
	getAd,
	getPageFragment,
} from '@/api/common';

export default {
	name: 'About',
	components: {
		CertificateDetail,
	},
	data() {
		return {
			pageUrl: '/about',
			cId: 6,
            adLink: '',
			aboutProductAreaData: {},
			networkAreaData: {},
			qualityAreaData: {},
			certificationAreaData: {},
			certificateList: [],
            defaultIframeType: 0,
			showMobileCertificateDetail: false,
			certificateDetailData: {},
			aboutTableData: [
				{
					id: 1,
					title: this.$t('aboutData.companyTableInfoData.lineTitle1'),
					content: this.$t('aboutData.companyTableInfoData.lineContent1'),
				},
				{
					id: 2,
					title: this.$t('aboutData.companyTableInfoData.lineTitle2'),
					content: this.$t('aboutData.companyTableInfoData.lineContent2'),
				},
				{
					id: 3,
					title: this.$t('aboutData.companyTableInfoData.lineTitle3'),
					content: this.$t('aboutData.companyTableInfoData.lineContent3'),
				},
				{
					id: 4,
					title: this.$t('aboutData.companyTableInfoData.lineTitle4'),
					content: this.$t('aboutData.companyTableInfoData.lineContent4'),
				},
				{
					id: 5,
					title: this.$t('aboutData.companyTableInfoData.lineTitle5'),
					content: this.$t('aboutData.companyTableInfoData.lineContent5'),
				},
				// {
				// 	id: 6,
				// 	title: this.$t('aboutData.companyTableInfoData.lineTitle6'),
				// 	content: this.$t('aboutData.companyTableInfoData.lineContent6'),
				// },
			],
		};
	},
	created() {
		this.init();
	},
    mounted() {
	},
	methods: {
		init() {
		},
		getAdFunc() {
			getAd({
				id: this.adId,
			}).then(res => {
				this.adLink = (((res || {}).items || [])[0] || {}).url;
			});
		},
		getPageFragmentFunc() {
			getPageFragment({
				cid: this.cId,
				lang: this.keyConfig[this.lang],
			}).then(res => {
				(res || []).forEach(item => {
					switch (item.code) {
						// 公司简介板块内容
						case 'key-6-1':
							this.aboutProductAreaData = item;
							break;
						// 营销网络板块内容
						case 'key-6-2':
							// eslint-disable-next-line
							item.subTitle = item.subTitle || [];
							this.networkAreaData = item;
							break;
						// 品质保证板块内容
						case 'key-6-3':
							this.qualityAreaData = item;
							break;
						// 质量认证板块内容
						case 'key-6-4':
							this.certificationAreaData = item;
							this.certificateList = item.pictures || [];
							break;
						default:
							break;
					}
				});
			});
		},
		handleToPage(item) {
			window.open(`/newsDetail?id=${item.id}`);
		},
		handleShowCertificateDetail(item) {
			this.showMobileCertificateDetail = true;
			this.certificateDetailData = item;
		},
		handleCloseProductDialog() {
			this.showMobileCertificateDetail = false;
			this.certificateDetailData = {};
		},
	},
	computed: {
        webMode() {
            return this.$store.state.webMode;
        },
		lang() {
			return this.$store.state.lang;
		},
		keyConfig() {
			return this.$store.state.keyConfig;
		},
		menuConfig() {
			return this.$store.state.menuConfig;
		},
		adId() {
			const { adId } = (this.menuConfig || []).find(item => item.position === 'main' && item.url === this.pageUrl) || {};

			return adId || '';
		},
    },
	watch: {
		adId: {
			immediate: true,
			handler(val) {
				if (val) {
					this.getAdFunc();
					this.getPageFragmentFunc();
				}
			},
		},
    },
};
</script>

<style scoped lang='scss'>
	.home {
		width: 100%;
		.content-box {
            .page-about-bg {
                display: block;
                width: 100%;
            }
            .intro-box {
				.intro-box-title {
					font-size: 40px;
					font-weight: bold;
					color: #333333;
					line-height: 40px;
				}
				.intro-box-content {
					font-size: 18px;
					color: #333333;
				}
				.btn {
					display: flex;
					align-items: center;
					justify-content: center;
					background: #C3002F;
					cursor: pointer;
					user-select: none;
				}
				.btn:hover {
					background: #a60028;
				}
				.intro-box-more {
					width: 210px;
					height: 68px;
					font-size: 24px;
					font-weight: 500;
					color: #FFFFFF;
				}
                .btn-download {
					width: 210px;
					height: 68px;
					font-size: 24px;
					font-weight: 500;
					color: #FFFFFF;
				}
			}
			.intro-box-content-text-red {
				font-weight: bold;
				color: #C3002F;
			}
            .page-about-product {
                padding-bottom: 120px;
				.page-content-box {
					display: flex;
					.intro-box-left {
						padding-top: 70px;
						height: 100%;
						img {
							width: 100%;
							margin-top: 50px;
						}
						.about-company-info {
							.about-company-info-node {
								display: flex;
								align-items: center;
								border-bottom: 1px solid #C3002F;
								height: 60px;
								font-size: 16px;
								color: #333333;
								.about-company-info-node-left {
									display: flex;
									align-items: center;
									padding-left: 28px;
									box-sizing: border-box;
									width: 120px;
									height: 100%;
									background: rgba(238, 230, 219, .5);
									word-wrap:break-word;
								}
								.about-company-info-node-right {
									display: flex;
									align-items: center;
									box-sizing: border-box;
									width: calc(100% - 120px);
									padding: 0 20px;
									word-wrap: break-word;
								}
							}
							.about-company-info-node:nth-child(1) {
								border-top: 1px solid #C3002F;
							}
						}
					}
                    .intro-box-right-dom {
                        display: flex;
                        .intro-box-right {
							display: flex;
							flex-direction: column;
                            margin-left: auto;
                            padding-top: 159px;
							img:nth-child(1) {
								margin-bottom: 30px;
							}
                        }
                    }
					.intro-box-content {
						margin-top: 43px;
						line-height: 32px;
						font {
							display: block;
						}
					}
				}
            }
            .marketing-network {
				padding-bottom: 105px;
				.page-content-box {
					.intro-box-content {
						margin: 55px 0 70px;
                        font-size: 18px;
						line-height: 32px;
                        font {
                            display: block;
                        }
						.marketing-network-title {
							margin-bottom: 25px;
							font-size: 24px;
							font-weight: bold;
							color: #333333;
							line-height: 32px;
						}
						.marketing-network-title-contract {
							margin-top: 40px;
						}
					}
					.intro-box-right-dom {
                        display: flex;
                        .intro-box-right {
							display: flex;
							flex-direction: column;
                            margin-left: auto;
                            padding-top: 95px;
							img:nth-child(1) {
								margin-bottom: 30px;
							}
                        }
                    }
				}
			}
			.quality-dom {
				padding-bottom: 170px;
				.page-content-box {
					.intro-box-content {
						margin: 55px 0 70px;
                        font-size: 18px;
						line-height: 32px;
                        font {
                            display: block;
                        }
						.quality-text-dom {
							margin-bottom: 60px;
						}
					}
					.intro-box-right-dom {
                        display: flex;
                        .intro-box-right {
							display: flex;
							flex-direction: column;
                            margin-left: auto;
                            padding-top: 95px;
							.quality-intro-list {
								width: 601px;
								.quality-intro-list-node {
									position: relative;
									margin-bottom: 25px;
									.quality-intro-list-node-linear {
										position: absolute;
										z-index: 100;
									}
									img {
										display: block;
									}
									.quality-intro-list-node-title {
										position: absolute;
										left: 25px;
										bottom: 25px;
										font-size: 18px;
										font-weight: bold;
										color: #FFFFFF;
										line-height: 18px;
										z-index: 101;
									}
								}
							}
                        }
                    }
				}
				.quality-intro-text-dom {
					margin-top: 80px;
					font-size: 18px;
					line-height: 32px;
				}
			}
            .quality-certification {
                padding-bottom: 60px;
                .intro-box-title {
                    margin-bottom: 55px;
                }
				.intro-box-content {
					line-height: 32px;
					margin-bottom: 70px;
				}
                .page-content-box {
                    .intro-box-list {
						.intro-box-list-node {
							margin-bottom: 120px;
							position: relative;
							cursor: pointer;
							.intro-box-list-node-img {
								overflow: hidden;
								border: 1px solid #b5b5b5;
								img {
									display: block;
									width: 100%;
									overflow: hidden;
									background-repeat: no-repeat;
									background-size: cover;
									background-position: 50%;
									background-color: #ccc;
									-webkit-transition: transform .3s ease-in-out;
									-webkit-transition: -webkit-transform .3s ease-in-out;
									transition: -webkit-transform .3s ease-in-out;
									transition: transform .3s ease-in-out;
									transition: transform .3s ease-in-out,-webkit-transform .3s ease-in-out;
									-webkit-transform-origin: center;
									transform-origin: center;
								}
								img:hover {
									-webkit-transform: scale(1.1);
									transform: scale(1.1);
								}
							}
							.intro-box-list-node-content {
								display: flex;
								flex-direction: column;
								align-items: center;
								justify-content: center;
								.intro-box-list-node-content-title {
                                    margin-top: 30px;
                                    font-size: 18px;
                                    color: #333333;
                                    line-height: 24px;
								}
							}
						}
					}
                }
            }
        }
		.mobile-content-box {
            .page-products-bg {
                display: block;
                width: 100%;
            }
            .intro-box {
				.intro-box-title {
					font-size: 28px;
					font-weight: bold;
					color: #333333;
					line-height: 28px;
				}
				.intro-box-content {
					font-size: 17px;
					color: #333333;
				}
				.btn {
					display: flex;
					align-items: center;
					justify-content: center;
					background: #C3002F;
					cursor: pointer;
					user-select: none;
				}
				.intro-box-more {
					height: 57px;
					font-size: 17px;
					font-weight: 500;
					color: #FFFFFF;
				}
			}
            .intro-box-content-text-red {
				font-weight: bold;
				color: #C3002F;
			}
            .page-about-product {
                padding-bottom: 60px;
				.page-content-box {
					.intro-box-left {
						padding-top: 40px;
						height: 100%;
						img {
							width: 100%;
							margin-top: 30px;
						}
						.about-company-info {
							.about-company-info-node {
								display: flex;
								align-items: center;
								border-bottom: 1px solid #C3002F;
								height: 60px;
								font-size: 14px;
								color: #333333;
								.about-company-info-node-left {
									display: flex;
									align-items: center;
									padding-left: 28px;
									box-sizing: border-box;
									width: 120px;
									height: 100%;
									background: rgba(238, 230, 219, .5);
									word-wrap:break-word;
								}
								.about-company-info-node-right {
									display: flex;
									align-items: center;
									box-sizing: border-box;
									width: calc(100% - 120px);
									padding: 0 20px;
									word-wrap: break-word;
								}
							}
							.about-company-info-node:nth-child(1) {
								border-top: 1px solid #C3002F;
							}
						}
					}
                    .intro-box-right-dom {
                        display: flex;
                        .intro-box-right {
							display: flex;
							flex-direction: column;
                            margin-left: auto;
                            padding-top: 25px;
							img {
								width: 100%;
							}
							img:nth-child(1) {
								margin-bottom: 25px;
							}
                        }
                    }
					.intro-box-content {
						margin-top: 36px;
						line-height: 32px;
						font {
							display: block;
						}
					}
				}
            }
            .marketing-network {
				padding-bottom: 80px;
				.page-content-box {
					.intro-box-content {
						margin: 36px 0 30px;
                        font-size: 17px;
						line-height: 27px;
                        font {
                            display: block;
                        }
						.marketing-network-title {
							margin-bottom: 25px;
							font-size: 24px;
							font-weight: bold;
							color: #333333;
							line-height: 32px;
						}
						.marketing-network-title-contract {
							margin-top: 34px;
						}
					}
					.intro-box-right-dom {
                        display: flex;
                        .intro-box-right {
							display: flex;
							flex-direction: column;
                            margin-left: auto;
							img {
								width: 100%;
							}
							img:nth-child(1) {
								margin-bottom: 30px;
							}
                        }
                    }
				}
			}
			.quality-dom {
				padding-bottom: 76px;
				.page-content-box {
					.intro-box-content {
						margin: 40px 0;
                        font-size: 17px;
						line-height: 27px;
                        font {
                            display: block;
                        }
						.quality-text-dom {
							margin-bottom: 30px;
						}
					}
					.intro-box-right-dom {
                        display: flex;
                        .intro-box-right {
							.quality-intro-list {
								.quality-intro-list-node {
									position: relative;
									margin-bottom: 25px;
									.quality-intro-list-node-linear {
										position: absolute;
										z-index: 100;
									}
									img {
										display: block;
										width: 100%;
									}
									.quality-intro-list-node-title {
										position: absolute;
										left: 13px;
										bottom: 13px;
										font-size: 16px;
										font-weight: bold;
										color: #FFFFFF;
										line-height: 16px;
										z-index: 101;
									}
								}
							}
                        }
                    }
				}
				.quality-intro-text-dom {
					margin-top: 45px;
					font-size: 17px;
					line-height: 27px;
				}
			}
            .quality-certification {
                padding-bottom: 78px;
                .intro-box-title {
                    margin-bottom: 35px;
                }
				.intro-box-content {
					font-size: 17px;
					line-height: 27px;
					margin-bottom: 35px;
				}
                .page-content-box {
                    .intro-box-list {
						border-top: 1px solid #EEEEEE;
						.intro-box-list-node {
							display: flex;
                            align-items: center;
                            height: 55px;
                            border-bottom: 1px solid #EEEEEE;
							.intro-box-list-node-content {
								display: flex;
								align-items: center;
                                width: 100%;
								.intro-box-list-node-content-title {
                                    padding-right: 5px;
                                    font-size: 17px;
                                    color: #333333;
								}
                                .intro-box-list-node-content-arrow {
                                    margin-left: auto;
                                    width: 12px;
                                }
							}
						}
					}
                }
            }
        }
	}
</style>
<style scoped lang="scss">
  .app-en {
    .app-pc {
		.about-company-info {
			.about-company-info-node {
				.about-company-info-node-left {
					width: 218px !important;
				}
				.about-company-info-node-right {
					width: calc(100% - 218px) !important;
				}
			}
		}
    }
	.app-mobile {
		.about-company-info {
			.about-company-info-node {
				.about-company-info-node-left {
					padding-left: 15px !important;
				}
				.about-company-info-node-right {
					line-height: 14px;
				}
			}
		}
	}
  }
  .app-ja {
    .app-pc {
		.about-company-info {
			.about-company-info-node {
				.about-company-info-node-left {
					width: 148px !important;
				}
				.about-company-info-node-right {
					width: calc(100% - 148px) !important;
				}
			}
		}
	}
	.app-mobile {
		.about-company-info {
			.about-company-info-node {
				.about-company-info-node-left {
					padding-left: 15px !important;
				}
				.about-company-info-node-right {
					line-height: 16px;
				}
			}
		}
	}
  }
</style>
